import React, { useEffect, useState } from 'react';
import advertisementSrc from 'assets/icons/advertisement.jpg';
import StepLayout from '../components/StepLayout';
import s from './Advertising.module.sass';
import { Placeholder } from 'react-bootstrap';

const Advertising = () => {
  const [isPreview, setIsPreview] = useState(true);
  useEffect(() => {
    const image = new Image();
    image.src = advertisementSrc;
    image.onload = () => {
      setIsPreview(false);
    };
  }, []);
  return (
    <StepLayout>
      {isPreview ? (
        <Placeholder animation="glow">
          <Placeholder xs={12} bg="secondary" className={s.imgPlaceholder} />
        </Placeholder>
      ) : (
        <img src={advertisementSrc} className="w-100" />
      )}
    </StepLayout>
  );
};

export default Advertising;
